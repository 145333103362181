import * as Sentry from '@sentry/vue'
import { createApp } from 'vue'

import { version } from '../../../package.json'

const app = createApp({})

if (import.meta.env.VITE_APP_ENV !== 'development') {
  console.log('Sentry.init')
  Sentry.init({
    app,
    release: version,
    environment: import.meta.env.VITE_APP_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    beforeSendTransaction(event) {
      return event.environment === 'production' && !event.transaction.includes('/control') ? event : null
    },
    integrations: [
      Sentry.browserTracingIntegration({
        traceFetch: false,
        beforeStartSpan: context => ({
          ...context,
          name: location.pathname
            .replace(/\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g, '/<hash>')
            .replace(/\/\d+/g, '/<digits>'),
        }),
      }),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [
      'localhost',
      'laravel-app-vb.docker',
      'varsitybase.com',
      'varsitybase.ops-gc-1.fra-1.do.repro.to',
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
